import React from "react";
import "./style.css";
import logo from "../../Assets/logo.png";
import { Link } from 'react-router-dom';

const TaxNews = () => {
  return (
    <React.Fragment>
      <div class="main_nav">
          <div class="container">
            <div class="mobile-toggle"> <span></span> <span></span> <span></span></div>
              <nav>
                <ul>
                <li><Link to="/firmprofile">Firm Profile</Link></li>
                  <li><Link to="/clientservices">Client Services</Link></li>
                  <li><Link to="/taxnews">Tax News</Link></li>
                  <li><Link to="/links">Links</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                  <li><Link to="/login">Login/Upload</Link></li>
                </ul>
              </nav>
          </div>
        </div>

        <div class="title">
          <div class="row">
            <div class="columns">
              <img src={logo} alt="logo" height="40" width="650" />
            </div>
          </div>

          <div class="mainnav">
            <div class="mobile-toggle"> <span></span> <span></span> <span></span></div>
              <nav>
                <ul>
                <li ><Link to="/firmprofile">Firm Profile<div class="fsmenublock"></div></Link></li>
                  <li><Link to="/clientservices">Client Services<div class="csmenublock"></div></Link></li>
                  <li><Link to="/taxnews">Tax News<div class="tnmenublock"></div></Link></li>
                  <li><Link to="/links">Links<div class="lmenublock"></div></Link></li>
                  <li><Link to="/contact">Contact<div class="cmenublock"></div></Link></li>
                  <li><Link to="/login">Login/Upload<div class="lsmenublock"></div></Link></li>
                  
                </ul>
              </nav>
          </div>


          <a class="smoothscroll" href="#firmprofile">
            <div class="mouse">
              <div class="wheel"></div>
            </div>
          </a> 

        </div>
        <section id="taxnews">
            <div class="container"> 
                <div> 
                    <h4>Tax News</h4>
                <div class="block"></div>
            </div>
            </div>
        </section>
    </React.Fragment>
  );
};

export default TaxNews;