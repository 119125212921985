import React from "react";
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import logo from "../../Assets/logo.png";
import "./style.css";

function SignupPage() {
  const initialValues = {
    name: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).max(15).required(),
    email: Yup.string().min(8).max(40).required(),
    password: Yup.string().min(8).max(20).required(),
  });

  const onSubmit = (data) => {
    axios.post("https://taxes-edrosolancpa.herokuapp.com/Auth", data).then(() => {
      alert ("Registration Successful");
    });
  };

  return (
    <div className="text-center m-3-auto">
      
        <div class="main_nav">
          <div class="container">
            <div class="mobile-toggle"> <span></span> <span></span> <span></span></div>
              <nav>
                <ul>
                  <li><Link to="/firmprofile">Firm Profile</Link></li>
                  <li><Link to="/clientservices">Client Services</Link></li>
                  <li><Link to="/taxnews">Tax News</Link></li>
                  <li><Link to="/links">Links</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                  <li><Link to="/login">Login/Upload</Link></li>
                </ul>
              </nav>
          </div>
        </div>

        <div class="title">
          <div class="row">
            <div class="columns">
              <img src={logo} alt="logo" height="40" width="650" />
            </div>
          </div>

          <div class="mainnav">
            <div class="mobile-toggle"> <span></span> <span></span> <span></span></div>
              <nav>
                <ul>
                  <li ><Link to="/firmprofile">Firm Profile<div class="fsmenublock"></div></Link></li>
                  <li><Link to="/clientservices">Client Services<div class="csmenublock"></div></Link></li>
                  <li><Link to="/taxnews">Tax News<div class="tnmenublock"></div></Link></li>
                  <li><Link to="/links">Links<div class="lmenublock"></div></Link></li>
                  <li><Link to="/contact">Contact<div class="cmenublock"></div></Link></li>
                  <li><Link to="/login">Login/Upload<div class="lsmenublock"></div></Link></li>
                  
                </ul>
              </nav>
          </div>


          <a class="smoothscroll" href="#firmprofile">
            <div class="mouse">
              <div class="wheel"></div>
            </div>
          </a> 

        </div>

       
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
          
        <Form>

            <h4>Create Account</h4>
            <p>     
            <label>Name</label><br/>
            <ErrorMessage name="name" component="span" />
            <Field autoComplete="off" name="name"/>
            </p>     
            <p>    
            <label>Email</label><br/>
            <ErrorMessage name="email" component="span" />
            <Field autoComplete="off" name="email"/>
            </p> 
            <p>
            <label>Password</label><br/>
            <ErrorMessage name="password" component="span" />
            <Field autoComplete="off" type="password" name="password"/>
            </p>

            <button id="sub_btn" type="submit"> Register</button>

        </Form>

      </Formik>

      <p><Link to="/login">Back to Login</Link></p> 
      <p><Link to="/">Hide</Link></p>
      <br/>
    </div>
  );
}

export default SignupPage;