import React, { useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import logo from "../../Assets/logo.png";
import "./style.css";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = () => {
    const data = { email: email, password: password };
    axios.post("https://taxes-edrosolancpa.herokuapp.com/Auth/login", data).then(() => {
        window.location.replace("/profile");
    });
  };
  return (
     <div className="text-center m-5-auto">

        <div class="main_nav">
          <div class="container">
            <div class="mobile-toggle"> <span></span> <span></span> <span></span></div>
              <nav>
                <ul>
                 <li><Link to="/firmprofile">Firm Profile</Link></li>
                  <li><Link to="/clientservices">Client Services</Link></li>
                  <li><Link to="/taxnews">Tax News</Link></li>
                  <li><Link to="/links">Links</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                  <li><Link to="/login">Login/Upload</Link></li>
                </ul>
              </nav>
          </div>
        </div>

        <div class="title">
          <div class="row">
            <div class="columns">
              <img src={logo} alt="logo" height="40" width="650" />
            </div>
            </div>

          <div class="mainnav">
            <div class="mobile-toggle"> <span></span> <span></span> <span></span></div>
              <nav>
                <ul>
                <li ><Link to="/firmprofile">Firm Profile<div class="fsmenublock"></div></Link></li>
                  <li><Link to="/clientservices">Client Services<div class="csmenublock"></div></Link></li>
                  <li><Link to="/taxnews">Tax News<div class="tnmenublock"></div></Link></li>
                  <li><Link to="/links">Links<div class="lmenublock"></div></Link></li>
                  <li><Link to="/contact">Contact<div class="cmenublock"></div></Link></li>
                  <li><Link to="/login">Login/Upload<div class="lsmenublock"></div></Link></li>
                  
                </ul>
              </nav>
          </div>


        </div>

    

<div id="form">
    <h4>Log in to Account</h4>
    <p>
      <label>Email</label><br/>
      <input type="text" onChange={(event) => {setEmail(event.target.value);}}/>
    </p>

    <p>
      <label>Password</label><br/>
      <input type="password" onChange={(event) => {setPassword(event.target.value);}}/>
    </p>

      <button id="sub_btn" onClick={login}> Login </button>


</div>

    <p><Link to="/forgotpassword">Forgot Password?</Link></p>
    <p><Link to="/signup">Create an Account</Link></p>
    <p><Link to="/">Hide</Link></p>


    </div>

  );
}

export default LoginPage;
