import React from "react";
// import { Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from "../../Assets/logo.png";
import emailjs from "emailjs-com";

function ProfilePage() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_fo92os6','template_mgyz1it',e.target,'user_nZ1OwYmQmUTrsK5t8ZPOw')
    .then(() => {
      alert ("Files Delivered");
  
    });
    e.target.reset()
  }


  return (
    <React.Fragment>
    <div className="text-center m-3-auto">
        <div className="main_nav">
          <div className="container">
            <div className="mobile-toggle"> <span></span> <span></span> <span></span></div>
              <nav>
                <ul>
                  <li><Link to="/profile">Profile</Link></li>
                  <li><Link to="/login">Logout</Link></li>
                </ul>
              </nav>
          </div>
        </div>

        <div class="title">
          <div class="row">
            <div class="columns">
              <img src={logo} alt="logo" height="40" width="650" />
            </div>
          </div>

          <div class="mainnav">
            <div class="mobile-toggle"> <span></span> <span></span> <span></span></div>
              <nav>
                <ul>
                  <li><Link to="/profile">Profile<div class="ppmenublock"></div></Link></li>
                  <li><Link to="/login">Logout<div class="lomenublock"></div></Link></li>
                  
                </ul>
              </nav>
          </div>


          <a class="smoothscroll" href="#firmprofile">
            <div class="mouse">
              <div class="wheel"></div>
            </div>
          </a> 

        </div>

    
    </div>
    {/* <div className="text-center m-3-auto"> */}

          <h1 className="text-center">Welcome to your account</h1> <br/>

          <h4 className="text-center">Submit your files: </h4>
          {/* <a target="_top" href="mailto:karlo@kate.edrosolancpa.com"><input class="button-primary" type="submit" value="Upload" /></a> */}
{/*           
          <p style={{paddingTop:"30px"}}>
            Having trouble? Email us directly and attach your files at karlo@kate.edrosolancpa.com
          </p> */}
{/* </div> */}
      <div className="text-center" style={{width:"100%"}}>

          <form className="row" onSubmit={sendEmail} style={{margin: "10px 450px 75px 450px"}}>
            <label>Name:</label>
            <input type="text" name="name" className="form-control" />
            <br/>

            <label>Email:</label>
            <input type="text" name="email" className="form-control" />
            <br/>

            <label>Message:</label>
            <br/>
            <textarea name="message" rows="4" className="form-control" />

            <label for="formFileMultiple" class="form-label">Upload Files:</label>

            <input class="form-control" type="file" name="attachments" id="formFileMultiple" multiple></input>
            <input  type="submit" value="Send" className="form-control" style={{marginTop: "30px"}} />



          </form>
      </div>

<br/>
        
    </React.Fragment>
  );
};

export default ProfilePage;

